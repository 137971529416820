import React from "react";
import { H2, Muted } from "./typography/typography";

interface OfferViewProps {
  title: string;
  description?: string;
}

export function TitleView({ title, description }: OfferViewProps) {
  return (
    <div className="py-3 px-3">
      <H2 className="font-bold">{title}</H2>
      {description && <div className="mt-4"> <Muted>{description}</Muted> </div>}
    </div>
  );
}